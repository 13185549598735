import React from 'react';
import { useTranslation } from 'react-i18next';
import Review from './Review';

function Reviews(props) {
    let { products } = props;

    const { t } = useTranslation();

    return (
        <div className="reviews px-4">
            {0 === products.length ?
                (<p>{t("reviews.p_1")}</p>) :
                (products.map((product, i) => <Review product={product} key={i} />))}
        </div>
    );
}

export default Reviews;
