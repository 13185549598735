import React from 'react';
import { useTranslation } from 'react-i18next';

function AllergenIcons(props) {
    const { icons } = props;

    const { t } = useTranslation();

    if (!icons || !Array.isArray(icons) || 0 === icons.length) {
        return null;
    }

    return (
        <div className="allergen-icons icons flex flex-wrap items-center">
            {icons.map((path, i) => (
                <img src={path} alt={t("allergenicons.img")} className="w-5 mr-2" key={i} />
            ))}
        </div>
    );
}

export default AllergenIcons;
