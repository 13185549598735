import React from 'react';
import { useRestaurant } from '../../utils/contexts/restaurant-context';
import Course from '../menu/Course';
import { useTranslation } from 'react-i18next';
import categoriesBuilder from '../../utils/categoriesBuilder';

function CalaveraTab(props) {
    const { restaurant } = useRestaurant();
    const { t } = useTranslation();

    return (
        (restaurant.courses && restaurant.courses.length > 0) ?
            categoriesBuilder.buildCategoriesTree(restaurant.courses).map((course, i) => <Course course={course} index={i} key={i} />) :
            //    todo: must be translated
            <p className="text-center">Nessun piatto disponibile</p>
    );
}

export default CalaveraTab;
