const ARABIAN = "ar";
const CHINESE = "zh-CN";
const ENGLISH = "en";
const GERMAN = "de";
const ITALIAN = "it";
const RUSSIAN = "ru";
const SPANISH = "es";
const JAPANESE = "ja";

const supported = [ARABIAN, CHINESE, ENGLISH, GERMAN, ITALIAN, RUSSIAN, SPANISH, JAPANESE];
const fallback = ITALIAN;

const options = [
    [ARABIAN, "عربى"],
    [GERMAN, "Deutsche"],
    [ENGLISH, "English"],
    [SPANISH, "Español"],
    [ITALIAN, "Italiano"],
    [JAPANESE, "日本人"],
    [RUSSIAN, "русский"],
    [CHINESE, "中文"],
];

export default {
    supported,
    fallback,
    options,
    ARABIAN,
    CHINESE,
    ENGLISH,
    GERMAN,
    ITALIAN,
    RUSSIAN,
    SPANISH,
    JAPANESE,
};
