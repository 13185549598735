import React, { useLayoutEffect } from 'react';
import { useParams } from 'react-router';
import { useTranslation } from 'react-i18next';
import checkout from '../logic/checkout';
import lemonway from '../logic/lemonway';
import { useCart } from '../utils/contexts/cart-context';
import { IonPage, IonContent, IonSpinner } from '@ionic/react';
import { useTracker } from '../utils/contexts/tracker-context';
import client from '../logic/whatsapp';
import formats from '../utils/formats';
import features from '../utils/features';
import { useRestaurant } from '../utils/contexts/restaurant-context';
import stripe from '../logic/stripe';

function OrderSuccessPage() {
    const { trackDataFn } = useTracker();

    const { type, id } = useParams();

    const { t } = useTranslation();

    const { payed, setPayed } = useCart();

    const { restaurant } = useRestaurant();

    useLayoutEffect(() => {
        async function storagePaypal() {
            let urlParams = new URLSearchParams(window.location.search);
            let storage = window.localStorage.getItem("__mycontactlessmenu_payload__");
            let coupon = window.sessionStorage.getItem("coupon");
            if (storage) {
                let payload = JSON.parse(storage);
                payload.order.payed = true;
                payload.transaction.id = /*urlParams.get("transactionid")*/ "noid";
                payload.transaction.provider = "paypal";

                if (coupon) {
                    coupon = JSON.parse(coupon);
                    if (coupon.restaurantAccepted == restaurant.id) {
                        payload.order.coupon = coupon;
                    }
                } else { payload.order.coupon = {} }

                let data = checkout.build(payload.restaurant, payload.order, payload.transaction);

                await trackDataFn(restaurant.id, restaurant.token, "sendorder", data, restaurant.has_orders);
                await checkout.doPost(data);
                if (restaurant.has_pony_u == true) {
                    await setTimeout(() => (
                        window.location.href = (`https://orders.mycontactlessmenu.mycia.it/orders/${data.order.id}/reviewDelivery`)
                    ), 3000)
                }
            }
        }
        async function storageSumup() {
            let urlParams = new URLSearchParams(window.location.search);
            let storage = window.localStorage.getItem("__mycontactlessmenu_payload__");
            let coupon = window.sessionStorage.getItem("coupon");
            if (storage) {
                let payload = JSON.parse(storage);
                payload.order.payed = true;
                payload.transaction.id = /*urlParams.get("transactionid")*/ "noid";
                payload.transaction.provider = "sumup";

                if (coupon) {
                    coupon = JSON.parse(coupon);
                    if (coupon.restaurantAccepted == restaurant.id) {
                        payload.order.coupon = coupon;
                    }
                } else { payload.order.coupon = {} }

                let data = checkout.build(payload.restaurant, payload.order, payload.transaction);

                await trackDataFn(restaurant.id, restaurant.token, "sendorder", data, restaurant.has_orders);
                await checkout.doPost(data);
                if (restaurant.has_pony_u == true) {
                    await setTimeout(() => (
                        window.location.href = (`https://orders.mycontactlessmenu.mycia.it/orders/${data.order.id}/reviewDelivery`)
                    ), 3000)
                }
            }
        }
        async function storageLemonway(transactions) {
            let storage = window.localStorage.getItem("__mycontactlessmenu_payload__");
            let coupon = window.sessionStorage.getItem("coupon");
            if (storage) {
                let payload = JSON.parse(storage);
                payload.order.payed = true;
                payload.transaction.id = transactions.value[0].id;
                payload.transaction.provider = "lemonway";

                if (coupon) {
                    coupon = JSON.parse(coupon);
                    if (coupon.restaurantAccepted == restaurant.id) {
                        payload.order.coupon = coupon;
                    }
                } else { payload.order.coupon = {} }

                let data = checkout.build(payload.restaurant, payload.order, payload.transaction);

                await trackDataFn(restaurant.id, restaurant.token, "sendorder", data, restaurant.has_orders);
                await checkout.doPost(data);
                if (restaurant.has_pony_u == true) {
                    await setTimeout(() => (
                        window.location.href = (`https://orders.mycontactlessmenu.mycia.it/orders/${data.order.id}/reviewDelivery`)
                    ), 3000)
                }
            }
        }
        async function storageCash() {
            let storage = window.localStorage.getItem("__mycontactlessmenu_payload__");
            let coupon = window.sessionStorage.getItem("coupon");
            if (storage) {
                let payload = JSON.parse(storage);

                if (coupon) {
                    coupon = JSON.parse(coupon);
                    if (coupon.restaurantAccepted == restaurant.id) {
                        payload.order.coupon = coupon;
                    }
                } else { payload.order.coupon = {} }

                let data = checkout.build(payload.restaurant, payload.order, payload.transaction);

                await trackDataFn(restaurant.id, restaurant.token, "sendorder", data, restaurant.has_orders);
                // client.doSendMessage(payload);
                await checkout.doPost(data);
                if (restaurant.has_pony_u == true) {
                    await setTimeout(() => (
                        window.location.href = (`https://orders.mycontactlessmenu.mycia.it/orders/${data.order.id}/reviewDelivery`)
                    ), 3000)
                }
            }
        }
        async function storageStripe() {
            let urlParams = new URLSearchParams(window.location.search);
            let storage = window.localStorage.getItem("__mycontactlessmenu_payload__");
            let coupon = window.sessionStorage.getItem("coupon");
            if (storage) {
                let payload = JSON.parse(storage);
                payload.order.payed = true;
                payload.transaction.id = urlParams.get("transactionid");
                payload.transaction.provider = "stripe";

                if (coupon) {
                    coupon = JSON.parse(coupon);
                    if (coupon.restaurantAccepted == restaurant.id) {
                        payload.order.coupon = coupon;
                    }
                } else { payload.order.coupon = {} }

                let data = checkout.build(payload.restaurant, payload.order, payload.transaction);

                await trackDataFn(restaurant.id, restaurant.token, "sendorder", data, restaurant.has_orders);

                var sripePayload = {
                    type: payload.order.type,
                    risId: payload.restaurant.id,
                    stripePaymentId: payload.transaction.id,
                    orderId: data.order.id,
                };

                await stripe.doPostTransaction(sripePayload);

                await checkout.doPost(data);

                if (restaurant.has_pony_u == true) {
                    await setTimeout(() => (
                        window.location.href = (`https://orders.mycontactlessmenu.mycia.it/orders/${data.order.id}/reviewDelivery`)
                    ), 3000)
                }
            }
        }
        async function sendToDashboard() {
            let urlParams = new URLSearchParams(window.location.search);
            if (urlParams.has("response_wkToken")) {
                setPayed(true);
                let transactions = await lemonway.moneyinsDetails(urlParams.get("response_wkToken"));

                storageLemonway(transactions);
            }
            if (urlParams.has("provider")) {

                let provider = urlParams.get("provider");
                if ("paypal" == provider) {
                    setPayed(true);
                    storagePaypal();
                }
                if ("cash" == provider) {
                    storageCash();
                }
                if ("sumup" == provider) {
                    setPayed(true);
                    storageSumup();
                }
                if ("stripe" == provider) {
                    setPayed(true);
                    storageStripe();
                }
            }
            window.localStorage.removeItem("__mycontactlessmenu_payload__");
            let coupon = window.sessionStorage.getItem("coupon");
            if (coupon) {
                window.sessionStorage.removeItem("coupon");
            }
        }

        sendToDashboard();
    }, []);

    return (
        <IonPage>
            <IonContent>
                {restaurant.has_pony_u == true ?
                    <div className="font-sans text-2xl flex items-center justify-center h-screen">
                        <IonSpinner name="crescent" className="text-mycia-red" />
                        <p className="px-6">Preparazione ordine</p>
                    </div>
                    :
                    <section id="success-page" className="relative pt-8 pb-32 font-sans text-center">
                        <div className="px-8">
                            {restaurant.id == 9288 ?
                                <p>Gentile cliente grazie per aver ordinato da tao, nei prossimi minuti la sua richiesta verrà accettata o rifiutata, la preghiamo di attendere fino al prossimo messaggio.</p>
                                :
                                <>
                                    <h2 className="font-bold text-4xl">Attesa conferma</h2>
                                    <p className="mt-8">La tua richiesta è stata inviata! Riceverai un SMS sullo stato dell'ordine.</p>
                                </>}
                        </div>
                        <img src="/assets/images/checkout_success.png" className="w-1/2 m-auto pt-12" alt="checkout success" />

                        <div className="fixed bottom-0 w-full flex flex-col justify-center mt-8 px-4 py-6 bg-white">
                            <a href={`/${type}/q/${id}/menu`} className="block py-2 rounded-full bg-mycia-green">{t("successpage.button_1")}</a>
                        </div>
                    </section>
                }
            </IonContent>
        </IonPage>
    );
}

export default OrderSuccessPage;
