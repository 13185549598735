import React from 'react';
import { IonContent, IonPage } from '@ionic/react';
import { useTranslation } from 'react-i18next';
import { useCart } from '../utils/contexts/cart-context';
import { useHistory, useParams } from 'react-router';

function OrdersErrorPage() {
    const { t } = useTranslation();

    const { clean } = useCart();
    const { type, id } = useParams();
    const history = useHistory();

    const onClickHandler = () => {
        clean();
        history.push(`/${type}/q/${id}/menu`);
    }

    return (
        <IonPage>
            <IonContent>
                <section id="orders-error-page" className="container mx-auto pt-12 px-8 font-sans">
                    <h3 className="mb-4 text-2xl font-medium">{t("orderserrorpage.p_1")}</h3>
                    <button onClick={onClickHandler} className="block py-3 px-3 m-auto rounded-full bg-mycia-green">{t("orderserrorpage.button_1")}</button>
                    <img className="mt-12" src="/assets/images/Img_error_404.png" alt="Error image" />
                </section>
            </IonContent>
        </IonPage>
    );
}

export default OrdersErrorPage;
