import React from 'react';
import { useTranslation } from 'react-i18next';
import formats from '../../utils/formats';

function Allergen(props) {
    const { allergen, total, index } = props;

    const { t } = useTranslation();

    let name = String(allergen.name).toLowerCase();
    let token = `allergens.elements.${formats.capitalize(name)}`;

    return (
        <span className="allergen font-bold">{t(token)}{(Boolean(Number(total - index - 1))) ? ", " : null}</span>
    );
}

export default Allergen;
