import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { IonModal } from '@ionic/react';
import TranslationModalContent from './TranslationModalContent';
import assets from '../../utils/assets';

function TranslationModalTrigger(props) {
    const { dish, type } = props;

    const { t } = useTranslation();

    const [showModal, setShowModal] = useState(false);

    const onOpenHandler = () => setShowModal(true);
    const onCloseHandler = () => setShowModal(false);

    return (
        <div className="translation-modal-trigger py-1">
            <div className="flex items-center">
                <img src={assets.assetsPath("svg/translation.svg")} alt="translation trigger" className="w-5 mr-2" />
                <button onClick={onOpenHandler} className="cursor-pointer pl-1 underline text-sm">{t("translationmodaltrigger.span_1")}</button>
            </div>

            <IonModal cssClass="custom-translation-modal-trigger" mode="md" isOpen={showModal} onDidDismiss={onCloseHandler}>
                <TranslationModalContent type={type} dish={dish} closerHandler={onCloseHandler} />
            </IonModal>
        </div>
    );
}

export default TranslationModalTrigger;
