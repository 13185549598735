import React, { useEffect, useRef, useState } from 'react';
import { useParams, useHistory } from 'react-router';
import { useTranslation } from 'react-i18next';
import { useRestaurant } from '../../utils/contexts/restaurant-context';
import { useCart } from '../../utils/contexts/cart-context';
import { PayPalButton } from "react-paypal-button-v2";

function PaypalWidget(props) {
    const { order } = props;

    const [formattedTotal, setFormattedTotal] = useState(null);

    const history = useHistory();
    const { type, id } = useParams();
    const { t } = useTranslation();
    const { restaurant } = useRestaurant();
    const { type: orderType } = useCart();

    let successURL = "";
    if (type == "r") {
        successURL = `/${type}/q/${id}/orders/success?provider=paypal`;
    } else {
        successURL = `/${type}/q/${id}/orders/awaitsms?provider=paypal`;
    }

    const errorURL = `/${type}/q/${id}/orders/error?provider=paypal`;

    useEffect(() => {
        if (order.order) {
            setFormattedTotal(order.order.total);
        }
    }, [order]);

    function renderPaypal() {
        let payment;
        if (orderType == "delivery") {
            payment = restaurant.orders.delivery.payment_methods.find(payment => payment.name === 'paypal');
        } else if (orderType == "takeaway") {
            payment = restaurant.orders.takeaway.payment_methods.find(payment => payment.name === 'paypal');
        } else if (orderType == "restaurant") {
            payment = restaurant.orders.tableservice.payment_methods.find(payment => payment.name === 'paypal');
        } else if (orderType == "shipment") {
            payment = restaurant.orders.shipment.payment_methods.find(payment => payment.name === 'paypal');
        }

        return (
            <PayPalButton
                amount={formattedTotal}
                onSuccess={(details, data) => {
                    console.log({ details, data });
                    history.push(successURL)
                }}
                onError={(err) => {
                    console.error(err);
                    alert("Pagamento non riuscito, riprova");
                }}
                options={{
                    clientId: "AczZ466jRDwhS9tEfSREAg4UHEzDJENzJ1b5MixYyEQGqSGdIkSpyBJhkpH_gG0dKTlEmg2WXIiFjAuf",
                    merchantId: payment.account,
                    currency: "EUR"
                }}
            />
        )
    }

    return (
        <div className="paypal-modal-content text-black min-h-screen container mx-auto pt-8 pb-16 overflow-y-scroll">

            <div className="px-8">
                <p className="text-2xl font-medium border-b border-b-gray-600">{t("paypalmodalcontent.p_1")}</p>

                {renderPaypal(order)}
            </div>
        </div>
    );
}

export default PaypalWidget;
