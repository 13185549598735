
async function shareContent(url, title, content) {
    if (!navigator.share) {
        return "";
    }

    var data = {
        "url": url,
        "title": title,
        "text": content,
    };

    try {
        return await navigator.share(data);
    } catch (err) {
        console.log(err);
    }
}

async function shareFiles(files, title, content) {
    if (!navigator.canShare || !navigator.canShare({ "files": files })) {
        return "";
    }

    var data = {
        "files": files,
        "title": title,
        "text": content,
    };

    try {
        return await navigator.share(data);
    } catch (err) {
        console.log(err);
    }
}

export default {
    shareContent,
    shareFiles,
}
