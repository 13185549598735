import React from 'react';
import cartReducer, { defaultState } from '../reducers/cart-reducer';
import actions from '../actions/cart';

const CartContext = React.createContext();

function useCartContextValue() {
    const [state, setState] = React.useReducer(cartReducer, defaultState);

    const { number, products, quantity, total, table, type, customers, payed, customer, deliveryCost, order_note } = state;

    const add = React.useCallback((item, quantity) => setState(actions.add(item, quantity)), []);
    const remove = React.useCallback(id => setState(actions.remove(id)), []);
    const addOne = React.useCallback(id => setState(actions.addOne(id)), []);
    const subOne = React.useCallback(id => setState(actions.subOne(id)), []);
    const clean = React.useCallback(() => setState(actions.clean()), []);
    const setTable = React.useCallback(table => setState(actions.setTable(table), []));
    const setNumber = React.useCallback(number => setState(actions.setNumber(number), []));
    const setType = React.useCallback(type => setState(actions.setType(type), []));
    const addNote = React.useCallback((id, note) => setState(actions.addNote(id, note), []));
    const addOrderNote = React.useCallback((note) => setState(actions.addOrderNote(note), []));
    const setCustomers = React.useCallback(number => setState(actions.setCustomers(number), []));
    const setDeliveryCost = React.useCallback(deliveryCost => setState(actions.setDeliveryCost(deliveryCost), []));
    const setPayed = React.useCallback(bool => setState(actions.setPayed(bool), []));
    const setCustomer = React.useCallback(bool => setState(actions.setCustomer(bool), []));


    const value = React.useMemo(() => {
        return {
            number,
            products,
            quantity,
            table,
            total,
            type,
            customers,
            payed,
            add,
            remove,
            addOne,
            subOne,
            clean,
            customer,
            deliveryCost,
            order_note,
            setTable,
            setNumber,
            setType,
            addNote,
            addOrderNote,
            setCustomers,
            setDeliveryCost,
            setPayed,
            setCustomer,
        }
    }, [
        number, products, quantity, table, total, type, customers, payed, deliveryCost, order_note,
        add, remove, addOne, subOne, clean, customer, setTable, setNumber, setType, addNote, addOrderNote, setCustomers, setDeliveryCost, setPayed, setCustomer
    ]);

    return value;
}

function CartProvider(props) {
    const value = useCartContextValue();
    return <CartContext.Provider value={value} {...props} />;
}

function useCart() {
    const context = React.useContext(CartContext);
    if (!context) {
        throw new Error("useCart must be used within a CartProvider");
    }
    return context;
}

export { CartProvider, useCart }
