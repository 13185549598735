import React from 'react';
import Allergen from './Allergen';

function Allergens(props) {
    const { allergens } = props;

    let total = allergens.length;

    return (
        <span className="allergens font-bold">
            {0 === allergens.length ?
                ("No allergens.") :
                (allergens.map((allergen, i) => <Allergen allergen={allergen} total={total} index={i} key={i} />))}
        </span>
    );
}

export default Allergens;
