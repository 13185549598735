import React, { useLayoutEffect } from "react";
import { useParams } from "react-router";
import { IonContent, IonPage } from "@ionic/react";
import { useTracker } from "../utils/contexts/tracker-context";
import { Link } from "react-router-dom";
import { useTranslation } from "react-i18next";

function RoadhouseSplashPage(props) {
    const { trackDataFn } = useTracker();

    const { type } = useParams();
    const { t } = useTranslation();

    useLayoutEffect(() => {
        async function track() {
            await trackDataFn("814", "zyyC4vqpY", "usersession", { "type": type }, false);
        }

        track();
    }, []);

    async function trackPageViewHandler() {
        await trackDataFn("814", "zyyC4vqpY", "pageview", { "type": type }, false);
    }

    return (
        <IonPage>
            <IonContent>
                <div className="h-screen bg-cover bg-center" style={{ backgroundImage: 'url("/assets/roadhouse/home.png")' }}>
                    <div className="absolute transform top-[54%] left-1/2 -translate-x-1/2 translate-y-1/2">
                        <Link onClick={trackPageViewHandler} to="/r/q/zyyC4vqpY/menu" className="block w-96 px-3 py-2 text-center font-bold text-white uppercase bg-[#BA0B31] rounded-full">{t("splash.button_1")}</Link>
                    </div>
                </div>
            </IonContent>
        </IonPage>
    );
}

export default RoadhouseSplashPage;
