import React, { useState } from 'react';
import { IonIcon, IonModal } from '@ionic/react';
import { useTranslation } from 'react-i18next';
import FilterModalContent from './FilterModalContent';
import { shuffle } from 'ionicons/icons';
import features from '../../utils/features';

function FilterModalTrigger(props) {
    const { t } = useTranslation();

    const [showModal, setShowModal] = useState(false);

    const onOpenHandler = () => setShowModal(true);
    const onCloseHandler = () => setShowModal(false);

    return (
        <React.Fragment>
            <div onClick={onOpenHandler} className="filter-modal-trigger cursor-pointer button-native flex items-center w-auto p-2 px-4 justify-center rounded-full shadow-lg bg-mycia-red text-white">
                <p>{t("filtermodaltrigger.button_1")}</p>
                <IonIcon className="pl-3" icon={shuffle} />
            </div>

            <IonModal isOpen={showModal} onDidDismiss={onCloseHandler}>
                <FilterModalContent closerHandler={onCloseHandler} />
            </IonModal>
        </React.Fragment>
    );
}

export default FilterModalTrigger;
