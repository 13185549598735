import React from 'react';

function Openings(props) {
    const { timetables } = props;

    return (
        <>
            {timetables.map(d =>
                <div className="flex justify-between">
                    <p>{d.day_of_week_formatted}</p>
                    <p className="mx-3">{d.hours}</p>
                </div>
            )}
        </>
    )
}

export default Openings;
