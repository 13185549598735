import axios from 'axios';
import httpAdapter from 'axios/lib/adapters/http';
import dl from '../utils/device-language';
import formats from '../utils/formats';

axios.defaults.adapter = httpAdapter; //solving cors problem

export const ENDPOINT_AUTH = "https://sandbox-api.lemonway.fr/oauth/api";

var instance = axios.create();

async function oauth() {
    const PATH = "/v1/oauth/token";

    try {
        let headers = {
            "Accept": "application/json;charset=UTF-8",
            "Authorization": `Basic ${process.env.REACT_APP_LEMONWAY}`,
            "Content-Type": "application/x-www-form-urlencoded",
        };

        let qs = new URLSearchParams();
        qs.append("grant_type", "client_credentials");

        let response = await instance.post(ENDPOINT_AUTH + PATH, qs, { headers: headers });

        return response.data;
    } catch (err) {
        console.log(err);
    }
}

const STORAGE_KEY = "__mycontactlessmenu_lemonway__";

function tokenExists() {
    return window.localStorage.getItem(STORAGE_KEY);
}

async function handleToken() {
    if (!tokenExists()) {
        let { access_token } = await oauth();

        window.localStorage.setItem(STORAGE_KEY, access_token);
    }
}

function onSend(config) {
    var token = tokenExists();
    if (token) {
        config.headers["Authorization"] = `Bearer ${token}`;
        config.headers["PSU-IP-Address"] = "0.0.0.0";
    }

    return config;
}

instance.interceptors.request.use(onSend, error => Promise.reject(error));

export const ENDPOINT_DIRECTKIT = "https://sandbox-api.lemonway.fr/mb/mycia/dev/directkitrest";

async function moneyInsCardWebinit(data) {
    const PATH = "/v2/moneyins/card/webinit";

    try {
        let response = await instance.post(ENDPOINT_DIRECTKIT + PATH, data);

        return response.data;
    } catch (err) {
        console.log(err);
    }
}

export const ENDPOINT_PAYMENT = "https://sandbox-webkit.lemonway.fr/mycia/dev/?moneyintoken=:token&lang=:lang";

function randomDebitID() {
    return "deb_" + (Date.now().toString(36) + "_" + Math.random().toString(36).substr(-5)).toLowerCase();
}

async function debitCard(success, error, cancel, order) {
    let data = {
        "returnUrl": success,
        "errorUrl": error,
        "cancelUrl": cancel,
        "registerCard": false,
        "captureDelayedDays": 3,
        "moneyInNature": 0,
        "reference": randomDebitID(),
        "accountId": "MKP",
        "totalAmount": order.amount,
        "commissionAmount": order.commissions,
        "comment": "Order",
        "autoCommission": false,
    };

    let { webKitToken } = await moneyInsCardWebinit(data);

    return formats.formatUrl(ENDPOINT_PAYMENT, { "token": webKitToken, "lang": dl.getApiLanguage() });
}

async function moneyinsDetails(id) {
    const PATH = "/v2/moneyins?transactionMerchantToken=:id";

    try {
        let response = await instance.get(formats.formatUrl(ENDPOINT_DIRECTKIT + PATH, { "id": id }));

        return response.data.transactions;
    } catch (err) {
        console.log(err);
    }
}

export default {
    oauth,
    handleToken,
    moneyInsCardWebinit,
    debitCard,
    moneyinsDetails,
}
