import React, { useState } from 'react';
import Details from '../dish/Details';
import PreferredButton from '../UI/PreferredButton';
import formats from '../../utils/formats';
import { useRestaurant } from '../../utils/contexts/restaurant-context';
import { useParams } from 'react-router';
import { useTracker } from '../../utils/contexts/tracker-context';
import CtaNoOrders from './CtaNoOrders';
import clsx from "clsx";
import assets from '../../utils/assets';

function Dish(props) {
    const { dish, isTastingMenu } = props;

    const { restaurant } = useRestaurant();
    const { type } = useParams();
    const { trackDataFn } = useTracker();

    const [showDishModal, setShowDishModal] = useState(false);

    async function onDetailsOpenHandler() {
        await trackDataFn(restaurant.id, restaurant.token, "dishview", { "type": type, "dish": dish.id, "name": dish.name, "price": dish.price }, restaurant.has_orders);
        try {
            await window.fbq("track", "ViewContent");
        } catch (e) {
            console.log({ pixelError: e })
        }
        finally {
            setShowDishModal(true);
        }
    };

    return (
        <div className="dish p-2">
            <div className={clsx("flex rounded-lg shadow bg-grey-100", { "rounded-b-none": dish.suggested_drink })}>
                <div className="flex-1 py-2 px-4">
                    <p className="flex items-center font-semibold">
                        {formats.capitalize(dish.name)}
                        {formats.shouldDisplayFilterIcon(dish) ? <img className="inline-block pl-1 w-6 mr-2" alt="filter icon" src={dish.icon == "images/icons/menu/ic_tick_gray.png" ? assets.assetsPath("svg/legend/triangolo_grigio.svg") : assets.assetsPath(dish.icon)} /> : null}
                    </p>

                    <Details dish={dish} showDishModal={showDishModal} setShowDishModal={setShowDishModal} onDetailsOpenHandler={onDetailsOpenHandler} />
                </div>
                <div className="flex flex-col items-end py-2 pr-4 text-base">
                    <PreferredButton item={dish} beverage={false} />

                    {!isTastingMenu && formats.cleanPrice(dish.price) !== "" ? <p className="mt-2 font-bold">{formats.formatPrice(dish.price, restaurant.id)}</p> : null}

                    {dish.image_path != "" ? <img className="object-cover w-26 h-26 my-3 border border-grey-300 rounded-lg" alt={formats.capitalize(dish.name)} onClick={onDetailsOpenHandler} src={dish.image_path}></img> : null}
                </div>
            </div>

            {dish.suggested_drink != "" && <div className="suggested-drink px-3 py-1 text-sm bg-grey-400 rounded-b-lg">
                <p><span className="font-bold">Ottimo con: </span>{dish.suggested_drink.name}</p>
            </div>}

            {(dish.variations.length != 0 || dish.additions.length != 0) ? <CtaNoOrders element={dish} /> : null}
        </div>
    );
}

export default Dish;
