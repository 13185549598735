import axios from 'axios';
import formats from '../utils/formats';

export const ENDPOINT = "https://api-ristoratori.mycia.it/googleapis/maps/api/distancematrix/json/?origins=:restaurant_latitude,:restaurant_longitude&destinations=:user_latitude,:user_longitude&mode=driving";

async function doGetDistance(restaurantLat, restaurantLng, userLat, userLng) {
    var response = await axios.get(formats.formatUrl(ENDPOINT, { "restaurant_latitude": restaurantLat, "restaurant_longitude": restaurantLng, "user_latitude": userLat, "user_longitude": userLng }));
    if (response.data.rows[0] && response.data.rows[0].elements[0] && response.data.rows[0].elements[0].distance && response.data.rows[0].elements[0].distance.value) {
        return response.data.rows[0].elements[0].distance.value;
    } else {
        return null;
    }
}

function checkAddressIsInArea(address, area) {
    var position = new window.google.maps.LatLng(address.lat, address.lng);

    for (var i = 0; i < area.length; i++) {
        var obj = area[i];
        for (var prop in obj) {
            if (obj.hasOwnProperty(prop) && obj[prop] !== null && !isNaN(obj[prop])) {
                obj[prop] = +obj[prop];
            }
        }
    }

    const areaPoligon = new window.google.maps.Polygon({ paths: area });

    return window.google.maps.geometry.poly.containsLocation(position, areaPoligon);
}

export default {
    doGetDistance,
    checkAddressIsInArea,
}
