import React from 'react';
import { useTranslation } from 'react-i18next';
import { useHistory, useParams } from 'react-router';
import formats from '../../utils/formats';
import { useTracker } from '../../utils/contexts/tracker-context';
import { calendarOutline, informationCircle } from 'ionicons/icons';
import { IonIcon } from '@ionic/react';
import features from '../../utils/features';
import theme from '../../utils/theme';

function Splash(props) {
    const { restaurant } = props;
    const { trackDataFn } = useTracker();

    const history = useHistory();
    const { type, id } = useParams();
    const { t } = useTranslation();

    async function handler(path) {
        await trackDataFn(restaurant.id, restaurant.token, "pageview", { "type": type }, restaurant.has_orders);
        if (path == "menu") {
            history.push(formats.formatUrl("/:type/q/:id/menu", { "type": type, "id": id }));
        } else if (path == "booking") {
            history.push(formats.formatUrl("/:type/q/:id/bookingrestaurant", { "type": type, "id": id }))
        } else if (path == "info") {
            history.push(formats.formatUrl("/:type/q/:id/info", { "type": type, "id": id }))
        }
    }

    return (
        <div className="splash pb-16">
            <img src={restaurant.image_path} alt={t("splash.img_1")} className="w-full" />

            <div className="relative py-6 px-4 text-center">
                <h1 className="mb-8 text-4xl">{restaurant.name}</h1>

                <button type="button" onClick={() => handler("menu")} className="block w-full cursor-pointer py-3 px-8 rounded-md font-medium bg-mycia-blue text-white">
                    {process.env.REACT_APP_NAMESPACE == "shop" ? "SCOPRI I PRODOTTI" : theme.getCustomElement(restaurant.id, "splash", t)}
                </button>

                {process.env.REACT_APP_NAMESPACE != "shop" && restaurant.has_booking == true ?
                    <>
                        <button type="button" onClick={() => handler("booking")} className="my-8 block w-full flex items-center justify-center cursor-pointer py-3 px-8 rounded-md font-medium bg-mycia-red text-white">
                            <IonIcon className="mr-3 w-6" icon={calendarOutline} />
                            <p>PRENOTA UN TAVOLO</p>
                        </button>

                        <button type="button" onClick={() => handler("info")} className="bottom-0 block w-full flex items-center justify-center cursor-pointer py-3 px-8 border border-black rounded-md font-medium">
                            <img className="mr-3 w-6" src={informationCircle} />
                            <p>MAGGIORI INFORMAZIONI</p>
                        </button>
                    </>
                    : null}
            </div>
        </div>
    );
}

export default Splash;
