import types from "../actions/types";

export const defaultState = {
    "isLoading": true,
    "restaurant": null,
    "error": null,
    "search": null,
};

function reducer(state, action) {
    switch (action.type) {
        case types.RESTAURANT_FETCHING: {
            return {
                ...state,
                "isLoading": true,
            }
        }
        case types.RESTAURANT_SUCCESS: {
            return {
                ...state,
                "isLoading": false,
                "restaurant": action.restaurant,
            }

        }
        case types.RESTAURANT_ERROR: {
            return {
                ...state,
                "isLoading": false,
                "restaurant": null,
                "error": action.error
            }
        }
        case types.RESTAURANT_SEARCHING: {
            return {
                ...state,
                "isLoading": false,
                "search": action.search,
            }
        }
        default: {
            throw new Error(`Unhandled action type: ${action.type}`);
        }
    }
}

export default reducer;
