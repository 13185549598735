import types from "../actions/types";

export const defaultState = {
    "items": [],
};

function reducer(state, action) {
    switch (action.type) {
        case types.PREFERENCES_ADD: {
            let newItem = action.payload;
            let exists = state.items.find(item => item.id === newItem.id);

            if (exists) {
                return state;
            }
            return {
                ...state,
                "items": [...state.items, newItem]
            }
        }
        case types.PREFERENCES_REMOVE: {
            let newItem = state.items.filter(item => item.id !== action.id);
            return {
                ...state,
                "items": newItem,
            }
        }
        default: {
            throw new Error(`Unhandled action type: ${action.type}`);
        }
    }
}

export default reducer;
