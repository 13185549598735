import React, { useLayoutEffect, useState } from 'react';
import { useCart } from '../../utils/contexts/cart-context';
import { IonSpinner } from '@ionic/react';

function Adder(props) {
    const { product } = props;

    const [actual, setActual] = useState(null);
    const { products, add, addOne, subOne } = useCart();

    const onSubOneHandler = () => subOne(actual.id);
    const onAddOneHandler = () => addOne(actual.id);

    useLayoutEffect(() => {
        let item = products.find(item => item.id === product.id);

        if (!item) {
            item = product;
            add(item);
        }
        setActual(item);
    }, [add, product]);

    return (
        <div className="adder flex justify-center items-center text-xl font-bold">
            <button type="button" onClick={onSubOneHandler} className="p-4">-</button>
            {actual ?
                <span className="quantity mx-2 py-2 px-4 rounded-full border-2 border-mycia-green">{actual.quantity}</span> :
                <IonSpinner name="bubbles" />}
            <button type="button" onClick={onAddOneHandler} className="p-4">+</button>
        </div>
    );
}

export default Adder;
