import React from 'react';
import filtersReducer, { defaultState } from '../reducers/filters-reducer';
import actions from '../actions/filters';

const FiltersContext = React.createContext();

function FiltersProvider(props) {
    const [state, setState] = React.useReducer(filtersReducer, defaultState);

    const { filters, error } = state;

    const setFilters = React.useCallback(filters => {
        setState(actions.success(filters));
    });

    const value = React.useMemo(() => {
        return {
            error,
            filters,
            setFilters,
        }
    }, [error, filters, setFilters]);

    return <FiltersContext.Provider value={value} {...props} />;
}

function useFilters() {
    const context = React.useContext(FiltersContext);
    if (!context) {
        throw new Error("useFilters must be used within a FiltersProvider");
    }
    return context;
}

export { FiltersProvider, useFilters }
