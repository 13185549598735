import { IonIcon, IonModal } from '@ionic/react';
import { call } from 'ionicons/icons';
import React, { useLayoutEffect, useState } from 'react';
import CallRestaurantModalContent from './CallRestaurantModalContent';

function CallRestaurantModalTrigger(props) {
    const { restaurant } = props;
    const [showModal, setShowModal] = useState(false);

    const onCloseHandler = () => setShowModal(false);

    return (
        <div className="callrestaurant-modal-trigger py-1">
            <button onClick={() => setShowModal(true)} className="button-native w-10 h-10 flex items-center justify-center rounded-full shadow-lg bg-mycia-green text-white">
                <IonIcon icon={call} size="medium" className="mb-1 text-xl"></IonIcon>
            </button>
            <IonModal cssClass="delivery-popup-modal-trigger" mode="md" isOpen={showModal} onDidDismiss={onCloseHandler}>
                <CallRestaurantModalContent restaurant={restaurant} onClickHandler={onCloseHandler} />
            </IonModal>
        </div>
    );
}

export default CallRestaurantModalTrigger;
