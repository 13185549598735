import React from 'react';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';

function UltraBrasserieSplash(props) {
    const { t } = useTranslation();

    return (
        <div className="splash pb-16">
            <div className="flex justify-center items-center">
                <div className="flex flex-col items-center p-4 space-y-6">
                    <img src="/assets/ultrabrasserie/logo.png" alt={t("splash.img_1")} className="w-[70%]" />
                    <Link to="/r/q/VBida73j9/menu" className="w-[90%] px-3 py-1 text-center text-white uppercase bg-black rounded-full">{t("splash.button_1")}</Link>
                </div>
            </div>
        </div>
    );
}

export default UltraBrasserieSplash;
