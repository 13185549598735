import React, { useState, useEffect } from 'react';
import client from '../../logic/filterModal';
import { useTranslation } from 'react-i18next';
import { IonIcon } from '@ionic/react';
import AsyncSelect from 'react-select/async';
import { close } from 'ionicons/icons';
import { useFilters } from '../../utils/contexts/filters-context';
import assets from '../../utils/assets';
import { useTracker } from '../../utils/contexts/tracker-context';
import { useRestaurant } from '../../utils/contexts/restaurant-context';

function FilterModalContent(props) {
    const { closerHandler } = props;
    const { filters, setFilters } = useFilters();
    const { trackDataFn } = useTracker();
    const { restaurant } = useRestaurant();

    const [loading, setLoading] = useState(true);
    const [data, setData] = useState({ allergies: null, foods: null, diets: null })

    const { t } = useTranslation();

    useEffect(() => {
        async function fetchData() {
            const resAll = await client.doGetAllergies();
            const resFoods = await client.doGetFoods();
            const resDiets = await client.doGetDiets();

            let allergies = resAll.map((el) => ({
                label: el.name,
                value: el.id
            }));
            setData(prevState => ({ ...prevState, allergies: allergies }));

            let foods = resFoods.map((el) => ({
                label: el.nome,
                value: el.id
            }));
            setData(prevState => ({ ...prevState, foods: foods }));

            let diets = resDiets.map((el) => ({
                label: el.name,
                value: el.id
            }));
            setData(prevState => ({ ...prevState, diets: diets }));

            setLoading(false);

        }

        fetchData();

    }, [])

    function filterData(type, inputValue) {
        if (type === "allergies") {
            return data.allergies.filter((i) =>
                i.label.toLowerCase().includes(inputValue.toLowerCase())
            );
        } else if (type === "foods") {
            return data.foods.filter((i) =>
                i.label.toLowerCase().includes(inputValue.toLowerCase())
            );
        } else if (type === "diets") {
            return data.diets.filter((i) =>
                i.label.toLowerCase().includes(inputValue.toLowerCase())
            );
        }
    };

    async function save(e) {
        e.preventDefault();

        setFilters({ allergies: filters.allergies, foods: filters.foods, diets: filters.diets });

        await trackDataFn(restaurant.id, restaurant.token, "filterapplied", { "allergies": filters.allergies, "foods": filters.foods, "diets": filters.diets }, restaurant.has_orders);

        closerHandler();
    }

    return (
        <div className="filter-modal-content overflow-y-scroll text-black">
            <div className="relative mb-4 " style={{ "minHeight": "2rem" }}>
                <button type="button" onClick={closerHandler} className="z-10 absolute top-0 right-0 flex items-center justify-center mr-3 mt-2 p-2 rounded-full shadow-lg bg-white">
                    <IonIcon slot="icon-only" icon={close}></IonIcon>
                </button>
            </div>
            <div className="p-6 pt-0">
                <p className="text-sm text-center">{t("filtermodalcontent.p_1")}</p>
                <div className="pt-3">
                    <p>{t("filtermodalcontent.p_2")}</p>
                    {!loading && <AsyncSelect
                        isMulti
                        cacheOptions
                        defaultOptions
                        defaultValue={filters && filters.allergies && filters.allergies.map(el => (
                            { "label": el.label, "value": el.value }
                        ))}
                        loadOptions={(inputValue, callback) => callback(filterData("allergies", inputValue))}
                        onChange={(data) => setFilters({ ...filters, allergies: data })}
                        placeholder=""
                    />}
                </div>
                <div>
                    <p className="pt-3">{t("filtermodalcontent.p_3")}</p>
                    {!loading && <AsyncSelect
                        isMulti
                        cacheOptions
                        defaultOptions
                        defaultValue={filters && filters.foods && filters.foods.map(el => (
                            { "label": el.label, "value": el.value }
                        ))}
                        loadOptions={(inputValue, callback) => callback(filterData("foods", inputValue))}
                        onChange={(data) => setFilters({ ...filters, foods: data })}
                        placeholder=""
                    />}
                </div>
                <div>
                    <p className="pt-3">{t("filtermodalcontent.p_4")}</p>
                    {!loading && <AsyncSelect
                        isMulti
                        cacheOptions
                        defaultOptions
                        defaultValue={filters && filters.diets && filters.diets.map(el => (
                            { "label": el.label, "value": el.value }
                        ))}
                        loadOptions={(inputValue, callback) => callback(filterData("diets", inputValue))}
                        onChange={(data) => setFilters({ ...filters, diets: data })}
                        placeholder={t("filtermodalcontent.placeholder_3")}
                    />}
                </div>

                <p className="pt-4 pb-2 text-sm text-center">Le seguenti icone si intendono come indicative, occorre sempre chiedere conferma al locale sugli ingredienti presenti nel piatto per verificarne la compatibilità.</p>

                <div className="pt-3 overflow-y-auto text-sm text-black">
                    <p className="text-2xl text-center text-mycia-red font-extrabold">{t("filterslegendmodalcontent.p_1")}</p>
                    <p className="px-3 py-3 text-base text-center text-mycia-medium">{t("filterslegendmodalcontent.p_2")}</p>
                    <ul className="px-3 text-mycia-medium">
                        <li className="flex py-4 items-center">
                            <div className="flex-none w-8"><img src={assets.assetsPath("svg/legend/triangolo_grigio.svg")} alt="icons legend" /></div>
                            <div className="ml-8">
                                <p>{t("filterslegendmodalcontent.li_1.fragment_1")}<span className="font-bold text-mycia-red"> {t("filterslegendmodalcontent.li_1.span_1")}</span>).</p>
                            </div>
                        </li>
                        <li className="flex py-4 items-center">
                            <div className="flex-none w-8"><img src={assets.assetsPath("svg/legend/croce_arancione.svg")} alt="icons legend" /></div>
                            <div className="ml-8">
                                <p>{t("filterslegendmodalcontent.li_2.fragment_1")}<span className="font-bold text-red-600"> {t("filterslegendmodalcontent.li_2.span_1")}</span>).</p>
                            </div>
                        </li>
                        <li className="flex py-4 items-center">
                            <div className="flex-none w-8">
                                <svg xmlns="http://www.w3.org/2000/svg" className="fill-current text-yellow-400" viewBox="0 0 591.43 591.43">
                                    <g data-name="Livello 2">
                                        <path d="M551.77 147.28q39.66 68 39.66 148.43t-39.66 148.44a294.42 294.42 0 01-107.62 107.62q-68 39.65-148.44 39.66t-148.43-39.66A294.34 294.34 0 0139.66 444.15Q0 376.2 0 295.71t39.66-148.43A294.42 294.42 0 01147.28 39.66Q215.23 0 295.71 0t148.44 39.66a294.49 294.49 0 01107.62 107.62zM442.42 382.73a23.71 23.71 0 00-7.32-17.32l-69.69-69.7L435.1 226a23.7 23.7 0 007.32-17.33A24.16 24.16 0 00435.1 191l-34.65-34.65a24.13 24.13 0 00-17.72-7.35 23.66 23.66 0 00-17.32 7.32L295.71 226 226 156.33a23.68 23.68 0 00-17.31-7.33 24.12 24.12 0 00-17.69 7.33L156.33 191a24.12 24.12 0 00-7.33 17.69 23.66 23.66 0 007.33 17.31L226 295.71l-69.69 69.7a23.67 23.67 0 00-7.31 17.32 24.13 24.13 0 007.32 17.72L191 435.1a24.12 24.12 0 0017.71 7.32A23.68 23.68 0 00226 435.1l69.69-69.69 69.7 69.69a23.66 23.66 0 0017.32 7.32 24.13 24.13 0 0017.72-7.32l34.65-34.65a24.16 24.16 0 007.34-17.72z" data-name="Livello 1" />
                                    </g>
                                </svg>
                            </div>
                            <div className="ml-8">
                                <p>{t("filterslegendmodalcontent.li_3")}</p>
                            </div>
                        </li>
                    </ul>
                </div>

                <div className="sticky bottom-0 py-4">
                    <button onClick={save} className="button-native flex items-center w-full p-2 mt-8 justify-center rounded-full shadow-lg bg-grey-100 text-blue-600">{t("filtermodalcontent.button_1")}</button>
                </div>
            </div>
        </div>

    );
}

export default FilterModalContent;
