import { IonModal } from '@ionic/react';
import React, { useLayoutEffect, useState } from 'react';
import DeliveryPopupModalContent from './DeliveryPopupModalContent';

function DeliveryPopupModalTrigger() {
    const [showModal, setShowModal] = useState(false);

    const onCloseHandler = () => setShowModal(false);

    useLayoutEffect(() => {
        setTimeout(() => setShowModal(true), 1000);
    }, []);

    return (
        <div className="delivery-popup-modal-trigger py-1">
            <IonModal cssClass="delivery-popup-modal-trigger" mode="md" isOpen={showModal} onDidDismiss={onCloseHandler}>
                <DeliveryPopupModalContent onClickHandler={onCloseHandler} />
            </IonModal>
        </div>
    );
}

export default DeliveryPopupModalTrigger;
