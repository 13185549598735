import axios from 'axios';
import dl from '../utils/device-language';
import formats from '../utils/formats';

export const LANGUAGE = "https://api-ristoratori.mycia.it/v2/languages/?code=:lang";
export const SUGGESTED_TRANSLATION = "https://api-ristoratori.mycia.it/v2/suggested_translations/";

async function doGet() {
    var response = await axios.get(formats.formatUrl(LANGUAGE, { "lang": dl.getDeviceLanguage() }));

    return response && response.data;
}

async function doPost(payload) {
    const fd = new FormData();
    fd.append("id", payload.id);
    fd.append("text", payload.text);
    fd.append("lang", payload.lang);
    fd.append("lang_id", payload.lang_id);
    fd.append("type", payload.type);

    var response = await axios.post(SUGGESTED_TRANSLATION, fd);

    return response && response.data;
}

export default {
    doGet,
    doPost,
}
