import React from 'react';
import { useCart } from '../../utils/contexts/cart-context';
import { useHistory, useParams } from 'react-router';
import { useTranslation } from 'react-i18next';

function Bucket() {
    const history = useHistory();
    const { type, id } = useParams();

    const { t } = useTranslation();

    const { quantity } = useCart();

    let classnames = [
        "bucket", "cursor-pointer", "fixed bottom-0", "w-full",
        "flex flex-col items-center", "pt-4 pb-2", "rounded-t-lg", "text-sm",
        "bg-mycia-green", "transition-all ease-in duration-700",
    ];

    if (quantity < 1) {
        classnames.push("hidden");
    }

    const onClickHandler = () => {
        const urlParams = new URLSearchParams(window.location.search);
        let shareid = urlParams.get("shareid");
        if (shareid) {
            history.push(`/${type}/q/${id}/orders/?shareid=${shareid}`);
        } else {
            history.push(`/${type}/q/${id}/orders`);
        }
    };

    // const onClickHandler = async () => {
    //     try {
    //         await window.fbq('track', 'ViewContent');
    //     } catch (e) {
    //         console.log({ pixelError: e })
    //     } finally {
    //         history.push(`/${type}/q/${id}/orders`);
    //     }
    // }

    return (
        <div onClick={onClickHandler} className={classnames.join(" ")}>
            <div className="relative w-10 h-10 p-2 rounded-full border border-black">
                <span className="quantity absolute -mt-4 ml-8 font-bold">{quantity}</span>
                <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 60 49.45">
                    <g>
                        <path d="M5.51 0H60v43.59h-6.91v5.86H5.51A5.52 5.52 0 013.34 49a5.32 5.32 0 01-1.76-1.17 5.39 5.39 0 01-1.17-1.7A5.26 5.26 0 010 44.06V5.51a5.06 5.06 0 01.47-2.17 6 6 0 011.17-1.7A6 6 0 013.34.47 5.06 5.06 0 015.51 0zm0 45.94h44.06V35.39H39.49a5.74 5.74 0 01-2.17-.39 5.18 5.18 0 01-1.75-1.17 5.59 5.59 0 01-1.18-1.7 5 5 0 01-.41-2V26.6a5.29 5.29 0 01.41-2.11 5.72 5.72 0 011.18-1.7 5.49 5.49 0 011.75-1.17 5.74 5.74 0 012.17-.41h10.08V10.55H5a1.69 1.69 0 01-.41-.06l-.41-.12a1.27 1.27 0 00-.41-.06.33.33 0 01-.23-.11v33.86a1.54 1.54 0 00.17.71 3.49 3.49 0 00.41.64 1.22 1.22 0 00.59.41 2.53 2.53 0 00.8.12zm47.58-24.73h3.39V3.52h-51a1.54 1.54 0 00-.71.17 4.12 4.12 0 00-.64.41 1.22 1.22 0 00-.41.59 2.53 2.53 0 00-.12.82 1.66 1.66 0 00.12.64.93.93 0 00.41.47 2.66 2.66 0 00.64.29 2.21 2.21 0 00.74.09h47.58zm-13.6 10.67h17v-7.15h-17a1.94 1.94 0 00-.82.17 2.39 2.39 0 00-.58.41 2.6 2.6 0 00-.41.53 1.45 1.45 0 00-.18.76v3.52a1.19 1.19 0 00.18.64 2.35 2.35 0 00.41.53 2.14 2.14 0 00.58.41 1.8 1.8 0 00.82.18zm17 8.2v-4.69h-3.4v4.69z" />
                    </g>
                </svg>
            </div>

            <button type="button" onClick={onClickHandler} className="mt-2">{t("bucket.button_1")}</button>
        </div>
    );
}

export default Bucket;
