import axios from 'axios';
import formats from '../utils/formats';

export const ENDPOINT = "https://orders.mycontactlessmenu.mycia.it/api/next-available-pickup?latitude=:latitude&longitude=:longitude";
export const ZONE = "https://orders.mycontactlessmenu.mycia.it/api/next-available-pickup-with-zone?latitude=:latitude&longitude=:longitude&zone=:zone";

async function doGet(latitude, longitude) {
    try {
        var response = await axios.get(formats.formatUrl(ENDPOINT, { "latitude": latitude, "longitude": longitude }));

        return response && response.data;
    } catch (e) {
        console.log({ e });
    }

}

async function doGetWithZone(latitude, longitude, zone) {
    try {
        var response = await axios.get(formats.formatUrl(ZONE, { "latitude": latitude, "longitude": longitude, "zone": zone }));

        return response && response.data;
    } catch (e) {
        console.log({ e });
    }

}

export default {
    doGet,
    doGetWithZone,
}
