import axios from 'axios';

export const SUBSCRIPTION = "https://api-ristoratori-v3.mycia.it/restaurants/newsletter/subscriptions";

async function doPost(form) {
    var response = await axios.post(SUBSCRIPTION, form);
    return response && response.data;
}

export default {
    doPost,
}
